<template>
	<div 
	v-if="!authenticated"
	class="search-btn">
		<i 
		@click="search"
		class="icon-search"></i>
	</div>
</template>
<script>
export default {
	methods: {
		search() {
			if (this.route_name != 'Products') {
				this.$router.push({name: 'Products'})
			}
			setTimeout(() => {
				document.getElementById('search-component').focus()
			}, 100)
		}
	}
}
</script>
<style lang="sass">
.search-btn
	// margin-right: 10px
	display: inline-block
	color: rgba(0,0,0,.7)
	font-size: 1.1em
</style>